
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import './style.less';

const Edit: React.FC<any> = (props: Partial<CustomIconComponentProps>) => {
  const svg = (attr: any) => (
    <svg
      viewBox="0 0 18.48 18.13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...attr} {...props}
    >
      <g id="编辑" transform="translate(-668.542 -153.703)">
        <path
          id="路径_4472"
          data-name="路径 4472"
          d="M81.031,69.349a1.115,1.115,0,0,1,2.227,0v8.79a2.25,2.25,0,0,1-2.25,2.25H67.376a2.25,2.25,0,0,1-2.25-2.25V64.509a2.251,2.251,0,0,1,2.25-2.25h8.807a1.115,1.115,0,0,1,0,2.227H67.376a.023.023,0,0,0-.021.021V78.139a.021.021,0,0,0,.021.023H81.009a.023.023,0,0,0,.023-.021V69.349Z"
          transform="translate(603.416 91.444)"
          fill="#3b95f2"
        />
        <path
          id="路径_4473"
          data-name="路径 4473"
          d="M481.5,69.49a1.115,1.115,0,0,1,1.536,1.61l-8.049,7.672a1.115,1.115,0,1,1-1.538-1.612Z"
          transform="translate(203.688 84.616)"
          fill="#3b95f2"
        />
      </g>
    </svg>
  );
  let childProps = { ...props };
  Reflect.deleteProperty(childProps, 'onClick');
  return <Icon component={svg} {...childProps} />;
};

export default Edit;