import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from "react";


const UploadCloud: React.FC<any> = (props: Partial<CustomIconComponentProps>) => {
  const svg = (attr: any) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 63.999 58.664"
      style={{ width: '100%', height: '100%' }} {...attr} {...props}
    >
      <g id="组_2418" data-name="组 2418" transform="translate(-31.997 -64.014)">
        <path
          id="路径_4553"
          data-name="路径 4553"
          d="M83.742,77.252a21.343,21.343,0,0,0-39.489,0A16,16,0,0,0,48,108.812h6.1a2.133,2.133,0,1,0,0-4.267H48A11.735,11.735,0,0,1,46.162,81.22l1.269-.2.416-1.216a17.074,17.074,0,0,1,32.3,0l.418,1.216,1.269.2A11.735,11.735,0,0,1,80,104.546H73.511a2.133,2.133,0,1,0,0,4.267H80a16,16,0,0,0,3.746-31.56Z"
          transform="translate(0)"
          fill="var(--primary-color)"
        />
        <path
          id="路径_4554"
          data-name="路径 4554"
          d="M342.909,396.263a2.134,2.134,0,1,0,2.7-3.3l-10.536-8.627a2.133,2.133,0,0,0-2.7,0l-10.6,8.682a2.132,2.132,0,1,0,2.7,3.3l7.121-5.826V419.06a2.133,2.133,0,1,0,4.267,0V390.491Z"
          transform="translate(-269.727 -298.515)"
          fill="var(--primary-color)"
        />
      </g>
    </svg>
  )
  let childProps = { ...props }
  Reflect.deleteProperty(childProps, 'onClick')
  return <Icon component={svg} {...childProps} />
}

export default UploadCloud
