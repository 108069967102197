import { useThrottleFn } from 'ahooks';
import { useEffect } from 'react';
import type { routeParams } from '../../utils/createRoutes';

export function addRequireAuthForObject(obj: routeParams): routeParams {
  // children 存在
  if (obj.children && Array.isArray(obj.children) && obj.children.length > 0) {
    return { ...obj, requireAuth: true, children: addRequireAuthForArray(obj.children) };
  }
  // children 不存在
  return { ...obj, requireAuth: true };
}

export function addRequireAuthForArray(arr: Array<routeParams>): Array<routeParams> {
  return arr.map((item: routeParams): routeParams => {
    // children 存在
    if (item.children && Array.isArray(item.children) && item.children.length > 0) {
      return { ...item, requireAuth: true, children: addRequireAuthForArray(item.children) };
    }
    // children 不存在
    return { ...item, requireAuth: true };
  });
}

const isTouchBottom = (handler: Function) => {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 滚动条距离顶部的距离
  let windowHeight = document.documentElement.clientHeight || document.body.clientHeight; // 可视区的高度
  let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight; //dom元素的高度，包含溢

  if (scrollHeight <= windowHeight + scrollTop + 50) {
    handler();
  }
};

export const useTouchBottom = (fn: Function) => {
  const { run: useFn } = useThrottleFn(
    () => {
      if (typeof fn === 'function') {
        isTouchBottom(fn);
      }
    },
    { wait: 500, leading: false }
  );

  useEffect(() => {
    window.addEventListener('scroll', useFn);
    return () => {
      window.removeEventListener('scroll', useFn);
    };
  }, []);
};

export function praseSearchParams(searchParams: string) {
  return searchParams.split('&').reduce((prev: any, curr: string) => {
    // return
    const [key, value] = curr.replace('?', '').split('=');
    // console.log(key,value)

    return { ...prev, [key]: value };
  }, {});
}
export function parseCreateProjectFormValues(jsonData: any) {
  if (!jsonData) return {};

  return Object.keys(jsonData).reduce((prev: any, key: string) => {
    const value = jsonData[key];
    switch (key) {
      case 'other_information': {
        prev[key] = parseCreateProjectFormValues(value);
        break;
      }
      case 'actual_salary_limit': {
        if (value) { prev.actual_salary_unit = '面议'; prev[key] = value };
        break;
      }
      case 'trial_salary_limit': {
        if (value) { prev.trial_salary_unit = '面议'; prev[key] = value; }
        break;
      }
      case 'emergency_level': {
        prev[key] = Number(value) || 0;
        break;
      }
      case 'job_category':
      case 'interview_city':
      case 'city': {
        prev[key] = value?value.split('-'):value;
        break;
      }
      default: {
        if (value !== null) prev[key] = value;
        break;
      }
    }
    return prev;
  }, {});
}
export function parseCreateProjectJsonData(formValue: any) {
  if (!formValue) return {};
  return Object.keys(formValue).reduce((prev: any, key: string) => {
    const value = formValue[key];
    switch (key) {
      case 'other_information': {
        prev[key] = parseCreateProjectJsonData(value);
        break;
      }
      case 'trial_salary_unit':
      case 'actual_salary_unit': {
        prev[key] = value === '面议' ? null : value;

        break;
      }
      case 'job_category':
      case 'interview_city':

      case 'city': {
        prev[key] = value?.join?.('-');
        break;
      }
      case 'emergency_level': {
        prev[key] = value === 0 ? null : value;



        break;
      }
      default: {
        prev[key] = value;
        break;
      }
    }
    return prev;
  }, {});
}
export function parseSearchParams(searchParams: string) {
	if(!searchParams) return {}
	return searchParams.split('&').reduce((prev: any, curr: string) => {
		// return
		const [key, value] = curr.replace('?', '').split('=');
		return {...prev, [key]: value};
	}, {});
}

export function deepCopy(target: any) {
	let copyed_objs: any[] = []; //此数组解决了循环引用和相同引用的问题，它存放已经递归到的目标对象
	function _deepCopy(target: any) {
		if (typeof target !== 'object' || !target) {
			return target;
		}
		for (let i = 0; i < copyed_objs.length; i++) {
			if (copyed_objs[i].target === target) {
				return copyed_objs[i].copyTarget;
			}
		}
		let obj: Record<string, any> = {};
		if (Array.isArray(target)) {
			obj = []; //处理target是数组的情况
		}
		copyed_objs.push({target: target, copyTarget: obj});
		Object.keys(target).forEach(key => {
			if (obj[key]) {
				return;
			}
			obj[key] = _deepCopy(target[key]);
		});
		return obj;
	}
	
	return _deepCopy(target);
}
