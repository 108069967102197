import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import './style.less';

const Empty: React.FC<any> = (props: Partial<CustomIconComponentProps>) => {
  const svg = (attr: any) => (
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3751"
      {...attr} {...props}
    >
      <path
        d="M831.7 369.4H193.6L64 602v290.3h897.2V602L831.7 369.4zM626.6 604.6c0 62.9-51 113.9-114 113.9s-114-51-114-113.9H117.5l103.8-198h582.5l103.8 198h-281zM502.2 131h39.1v140.6h-39.1zM236.855 200.802l27.647-27.647 99.419 99.418-27.648 27.648zM667.547 272.637l99.418-99.419 27.648 27.648-99.418 99.418z"
        p-id="3752"
        fill="#999999"
      ></path>
    </svg>
  );
  let childProps = { ...props };
  Reflect.deleteProperty(childProps, 'onClick');
  return <Icon component={svg} {...childProps} />;
};

export default Empty;
